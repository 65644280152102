body {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  
}
.noClick {
  pointer-events: none;
}
.click:hover {
  cursor: pointer;
  filter: drop-shadow(0px 0px 10px rgb(255, 255, 255));
}

.noScroll::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

body::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.loading-icon {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 4em;
}
#headerBarDemo {
  height:1.2em;
  display: flex;
  align-items: center;
  justify-content: left;
  width:100%;
  background-image: linear-gradient( 90deg, #3e9eff 15%, #5268b9 100%);
  color:yellow;
  font-weight: bold;
  cursor: pointer;
  border-bottom: white 2px solid;

}
/*ff7800*/
#headerBar {
  user-select: none;
  width: 100vw;
  height: 2.9em;
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: space-evenly;
  background-color: rgba(40, 40, 40, 1);
  /* background-image: linear-gradient( #4e7938, #455b3b); */
  /* background-image: linear-gradient( #4e7938, #455b3b); */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 3px 10px 10px rgba(0,0,0,0.3);
  color: white;
  border-bottom: 2px solid white;
  /*COMMENT HERE DOWN TO REMOVE ANIMATION*/
  /* background-image: linear-gradient( -4deg, #f1c70d 0%, #ffec3e 15%, #57b952 100%);
  animation: unicorn-slide 10s ease infinite;
  -webkit-animation: unicorn-slide 1000s ease infinite;
  background-size: 200% 200%;
  overflow: hidden;
   */
}
@keyframes unicorn-slide {
  0% { background-position: 0% 50% }
  50% { background-position: 100% 50% }
  100% { background-position: 0% 50% }
}

@-webkit-keyframes unicorn-slide {
  0% { background-position: 0% 50% }
  50% { background-position: 100% 50% }
  100% { background-position: 0% 50% }
}
/* @keyframes transition-to-dark {
  0% {filter: invert(0.0)}
  100% {filter: invert(0.9)}
}
@keyframes transition-to-light {
  0% {filter: invert(0.9)}
  100% {filter: invert(0.0)}
} */
.pageNumInput-container {
  width: 4em;
  display: flex;
  flex-direction: row;
  font-size: 1.5em;
  align-items: center;
  justify-content: space-evenly;
  user-select: none;
}
.arrow-container {
  width: 4em;
  display: flex;
  flex-direction: row;
  font-size: 1.5em;
  align-items: center;
  justify-content: space-evenly;
  border-radius: .2em;
}
.tool-icon-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  user-select: none;
  width: 6%;
}
.tool-icon-box:hover {
  filter: drop-shadow(0px 0px 10px rgb(255, 255, 255));
  color: white;
}
.tool-icon-box:hover > .tool-icon {
  font-size: 2em;
}
.tool-icon{
  font-size: 1.5em;
  margin-bottom: -.4em;  
}
.tool-icon.zoom {
  margin-bottom: 0;
  font-size: 1.5em;
}
.tool-icon.zoom:hover {
  font-size: 1.8em;
  filter: drop-shadow(0px 0px 10px rgb(255, 255, 255));
  cursor: pointer;
}
.tool-text {
  margin-top: 0.2em;
  font-size: 0.8em;
}

.options-bar {
  user-select: none;
  position: fixed;
  top: 3em;
  z-index: 10;
  margin-top: 1em;
  margin-left: 0.5em;
  width: 6em;
  box-sizing: border-box;
  box-shadow: 3px 10px 10px rgba(0,0,0,0.3);
  background-color: rgba(40, 40, 40, 0.95);
  border-radius: 0.3em;
  animation: options-bar-anim .1s linear;
  display: flex;
  flex-direction: column;
  border: 2px solid white;
}
.zoomBar {
  user-select: none;
  position: fixed;
  top: 3.5em;
  right: 1em;
  z-index: 10;
  width: 5em;
  height: 2.5em;
  box-shadow: 3px 10px 10px rgba(0,0,0,0.3);
  background-color: rgba(40, 40, 40, 0.95);
  border-radius: 0.5em;
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: space-evenly;
  align-items: center;
}
.options-bar.mini {
  align-items: center;
  color: white;
  height: 30em;
  left: 6.2em;
  width: 3.6em;
  z-index: 9;
}
.options-bar.mini.half {
  width: 17em;
  height: 7em;
}
.options-bar.mini.scale {
  height: 4.5em;
  width: 8.5em;
}
.options-bar.palette {
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  color: white;
  height: 4em;
  left: 10em;
  width: 7em;
  z-index: 9;
}
.options-bar.multiplier {
  left: 22.5em;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  color: white;
}
.options-button.palette {
  border: 2px solid white;
  border-radius: 50%;
  padding: 0.2em .4em;
}
@keyframes options-bar-anim  {
  0% {margin-left: -3.5em}
  100% {margin-left: 0.5em}
}
.options-button-box {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  height: 10em;
  margin-top: .3em;
  margin-bottom: .3em;
}
.options-button-box.small {
  height: 5em;
}
.options-button-box.tiny {
  height: 3em;
  margin: 0;
}
.options-button-box:hover {
  filter: drop-shadow(0px 0px 10px rgb(255, 255, 255));
}
.options-button-box:hover > .options-button-icon {
  font-size: 2em;
}
.options-button-icon {
  font-size: 1.5em;
}
.options-button-text {
  display: flex;
  font-size: .7em;
}
.options-button-picture {
  height: 1.1em;
}
.options-button-picture.tool {
  height: 1em;
}

.pen-size-option {
  box-sizing: border-box;
  margin-top: .3em;
  margin-bottom: .3em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pen-size-option:hover {
  filter: drop-shadow(0px 0px 10px rgb(255, 255, 255));
  cursor: pointer;
  transform: scale(1.1);
}
.button-frame {
  height: 1.5em;
  width: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e5e5e5;
  border: 1px solid black;
  margin: 1px;
  color: black;
  cursor: pointer;
  /* border-radius: .01em; */
}
.button-frame:hover {
  box-shadow: 0px 0px 20px white;
  color: white;
}
.button-frame.addNote {
  width: 16em;
  height: 20%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 0.3em;
  user-select: none;
  border-radius: .1em;
}
.string-frame {
  background-color: white;
  width: 100%;
  height: 100%;
  color: black;
  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  user-select: none;
  font-size: 1.2em;
}
.string-frame::-webkit-scrollbar {
  display: none;
}
.color-frame {
  height: 1em;
  width: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e5e5e5;
  border: 1px solid black;
  margin: 1px;
}
.color-picker {
  width: 70%;
  height: 70%;
  border: 1px solid black;
  cursor: pointer;
}
.color-picker.noClick {
  cursor: default;
}

.transform-frame {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr;
  position: absolute;
  user-select: none;
  transform-origin: center;
}
.blueprint-frame {
  grid-row-start: 1;
 grid-column-start: 1;
 z-index: 1;
}

.blueprintImage.dark {
  filter: invert(0.9);
  animation: transition-to-dark 0.6s
}
.blueprintImage.light {
  filter: invert(0);
  /* animation: transition-to-light 1s */
}
.blueprintImage.fresh {
  animation: none;
}

.blink {
  animation: blinkAnim 20s infinite;
}
@keyframes blinkAnim {
0% {opacity: 1;}
5% {opacity: 0.3;}
10% {opacity: 1;}
40% {opacity: 1;}
100% {opacity: 1;}
}


.pin-layer {
  cursor: default;
}
.pin-layer.editPin {
  cursor: crosshair;
}


.pinGroup:hover {
  cursor: pointer;
}
.pinShape {
  filter: drop-shadow(0px 0px 5px rgba(0,0,0,0.4));
}
.pinShape:hover {
  filter:brightness(1.5) drop-shadow(0px 0px 10px rgba(0,0,0,0.6));
}
.pinShape.active {
  filter:brightness(1.5) drop-shadow(0px 0px 10px rgba(0,0,0,0.6));
}
.pinGroup.trashPin:hover {
  filter: drop-shadow(0px 0px 1em rgba(255, 0, 0, 1));
}

.pin-list-ui-row {
  width: 100%;
  border: 1px solid black;
  display: flex;
  flex-direction: row;
  color: white;
  font-size: 1em;
}

.marks.true {
  filter: drop-shadow(0px 0px 1em rgba(255, 0, 0, 1)) blur(0.1em);
  cursor: pointer;
}
.marks.false {
  filter: none;
  cursor: default;
}

.measures.true {
  filter: drop-shadow(0px 0px 1em rgba(255, 0, 0, 1)) blur(0.1em);
  cursor: pointer;
}
.measures.false {
  filter: none;
  cursor: default;
}
.stamps.selected {
  stroke: blue;
  stroke-width: 30;
}
.stamps.true:hover {
  filter: drop-shadow(0px 0px 1em rgba(255, 0, 0, 1)) blur(0.1em);
  cursor: pointer;
}
.stamps.false {
  filter: none;
  cursor: default;
}









.pin-list-ui-row:hover {
  cursor: pointer;
  background-color: #f1f1f1;
  color: black;
}
.fav-page-wrapper {
  user-select: none;
  width: 20em;
  height: 100vh;
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: space-evenly;
  background-color: rgba(40, 40, 40, 0.95);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 3px 10px 10px rgba(0,0,0,0.3);
  color: white;
  border: 2px solid #292929;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 5;
  animation: side-bar-anim .2s linear;
  padding-left: 0.5em;
}
.fav-page-list {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.fav-page-row {
  margin-top: .2em;
  font-size: 1.1em;
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid #f1f1f1;
  padding-bottom: 0.2em
}
.fav-page-row:hover {
  cursor: pointer;
  background-color: #f1f1f1;
  color: black;
}
@keyframes side-bar-anim  {
  0% {margin-right: -21em}
  100% {margin-right: 0em}
}
.fav-page-buttons-wrapper {
  width: 100%;
  height: 10%;
  margin: auto;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 1em;
  margin-top: 0.5em
}
.fave-page-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
}
.fave-page-button:hover {
  filter: drop-shadow(0px 0px 10px rgb(255, 255, 255));
  cursor: pointer;

}



/* .hoverBubbleTapeMeasure:hover {
  cursor: pointer;
  color: #eeeff3
}; */





.planSetButton:hover {
  cursor: pointer;
}








@keyframes dash {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: -160;
  }
}











.lasso-box{
  color:white;
  background-image: none;
}
.lasso-box-selected {
  background-image: linear-gradient(90deg,#ffffff 80%,#cacaca);
  color:black;
}
.lasso-box-selected.mini {
  background-image: none;
  background-color: white;
}
.lasso-box:hover {
  background-image: linear-gradient(90deg,#ffffff 80%,#cacaca);
  color:black;
}
.lasso-box.mini:hover {
  background-image: linear-gradient(#ffffff);
  background-color: white;
  color:black;
}










.buttonsFrame:hover > .buttonIcon {
  color: yellow;
}



































/*
SPLASH CSS
*/

.leftNavButton:hover {
  filter: drop-shadow(0px 0px 20px white);
  /* color:rgb(255, 255, 119); */
  background-color: #282828;
  transform: scale(1.03);
  /* font-family:fantasy; */
}
