* {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
}

.logo-wrapper {
  display: flex;
  width: 50vw;
  height: 50vh;
  position: relative;
  pointer-events: none;
  align-items: center;
  justify-content: center;
}
.App-logo {
  height: 40vmin;
  position: absolute;
  z-index: 1;
}
.App-logo-front {
  height: 50vmin;
  position: absolute;
 z-index: 2;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: rgb(0, 0, 0);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hero-wrapper {
  height:400px;
  display: flex;
  flex-direction: row;
  padding:40px;
}

.hero-text-box {
  padding:1.5em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  position:relative;
  width:36em;

}

.button-box {
  position: absolute;
  bottom:0;
  display: flex;
  flex-direction: row;
}
.hero-button {
  margin: 0 2em 0 0;
  height:3em;
  width:10em;
  border: 2px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  font-weight: bold;
  color: white;
  transition: border-radius 0.2s,filter 0.4s
}
.hero-button-big {
  margin: 0 2em 0 0;
  height:3em;
  width:16em;
  border: 2px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  font-weight: bold;
  color: white;
  transition: border-radius 0.2s,filter 0.4s
}
.hero-button.catalog {
  font-size: 1.2em;
}
.hero-button-big.catalog {
  font-size: 1.2em;
}
.hero-button:hover {
  filter: brightness(1.1);
  /* filter:drop-shadow(5px 5px 5px black); */
  cursor: pointer;
  border-radius: 8px;
}
.hero-button-big:hover {
  filter: brightness(1.1);
  /* filter:drop-shadow(5px 5px 5px black); */
  cursor: pointer;
  border-radius: 8px;

}

.pane-wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 40px;
  width:100vw;
  height:100%;
  align-items: center;
}
.pane-box-wrapper {
  height:90%;
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.pane-box {
  width:30%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.pane-details {
  text-align: left;
}
.pane-spacer {
  height:90%;
  background-color:#ffde17;
  width:0.4em;
  border-radius: 10px;
}
.pane-button {
  width:16em;
  height:3em;
  border: 2px solid #00aeef;
  transition: background-color .3s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pane-button-text {
  color:#00aeef;
  transition: color 0.3s, font-weight 0.1s;
}
.pane-button:hover {
  background-color: #ffde17;
  border-color: #1c1c1c;
  cursor: pointer;
}
.pane-button:hover > .pane-button-text {
  color:#1c1c1c;
  /* font-weight: bold; */

}
.catalog-hero-wrapper{
  height:38em;
  background-color: #1c1c1c;
  overflow: hidden;
}
.catalog-hero-container{
  display: flex;
  flex-direction: row;
}
.catalog-hero-description{
  color:"#fff";
  width:40%;
  padding: 2em;
  
}
.catalog-hero-bullets{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: start;
  padding: 1em;
}
.catalog-hero-img-wrapper{
  overflow: hidden;
  height: 40em;
  display: flex;
  align-items: center;
  justify-content: center;
  width:60%;
}
.catalog-hero-img{
  height:100%;
}
.catalog-pane-wrapper{
  height:28em;
  padding:2em;
  display: flex;
  align-items: center;
  flex-direction: row;
  width:100%;
  padding-top: 4em;
  padding-bottom: 4em;
  box-sizing: border-box;
}
.catalog-pane-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height:90%;
  padding: 2em;
}

.catalog-title {
  color:black;
}
.catalog-text {
  color:black;
  font-size: 1em;
  text-align: left;
}
.catalog-text-bold {
  color: black;
  font-size: 1.6em;
  text-align: left;
  font-weight: bold;
}
.catalog-spacer {
  height:1em;
}
.catlog-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

}
.catalog-pane-container{}
.catalog-img-wrapper {
  width:26em;
  height:26em;
  overflow: hidden;
  display: flex;
  border: 4px solid black;
  transition: filter 0.2s;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.about-img-wrapper {
  width:28em;
  height:26em;
  overflow: hidden;
  display: flex;
  border: 4px solid black;
  transition: filter 0.2s;
  position: relative;
  align-items: center;
  justify-content: center;
}
.about-pane-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height:90%;
  padding:2em;
  width:70%;
  font-size: 0.8em;
}
.about-pane-wrapper {
  height:28em;
  padding:2em;
  padding-top: 4em;
  padding-bottom: 4em;
  display: flex;
  align-items: center;
  flex-direction: row;
  width:100vw;
  box-sizing: border-box;
}
.catalog-img-wrapper:hover {
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.4));
  cursor: pointer;
}
.catalog-img-screen {
  transition: background-color 0.2s;
  position: absolute;
  background-color: rgba(0,0,0,0);
  height: 100%;
  width: 100%;
}
.catalog-img-wrapper:hover > .catalog-img-screen {
  background-color: rgba(0, 0, 0, 0.5);
}
.catalog-img-wrapper:hover > .catalog-demo {
  color:#ffde17
}
.catalog-img {
  height: 100%;
  position: absolute;
}
.about-img {
  height: 100%;
  position: absolute;
  width: 100%;
  object-fit: cover;
}
.catalog-demo {
  transition: color .2s;
  line-height: 1em;
  position: absolute;
  color:rgba(0,0,0,0);
  font-size: 3.6em;
  font-weight: bold;
}

/* NAV BAR */
.NavBar {
  user-select: none;
  width: 100vw;
  height: 2.5em;
  display: flex;
  flex-direction:row;
  justify-content: space-evenly;
  background-color: rgba(40, 40, 40, 1);
  /* background-image: linear-gradient( #4e7938, #455b3b); */
  /* background-image: linear-gradient( #4e7938, #455b3b); */
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  box-shadow: 0px 5px 5px rgba(0,0,0,0.5);
  color: white;
  /* border-bottom: 2px solid white; */
  position:sticky;
  top:0;
  padding: 0.2em 0;
  z-index: 10;
}
.NavButtonBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  width: 15%;
  transition: filter 0.2s;
}
.NavButtonBox.icon {
  width:3%;
  align-items: flex-end;
  height:100%;
  margin-left: 1em;
}
.NavButtonIcon {
  font-size: 1.6em;
}
.NavButtonBox:hover {
  filter: drop-shadow(0px 0px 10px rgb(255, 255, 255));
  color: white;
}
.NavButtonBox:hover > .NavButtonIcon {
  font-size: 1.8em;
}
.NavButtonBox:hover > .NavButtonText {
  /* color:#61dafb */
  /* font-size:1.1em; */
  font-weight: bold;
}
.NavButtonText {
  font-size: 1.4em;
  transition: font-weight 0.05s
}
.NavButtonLogo {
  height:2.6em;
}
.NavButtonBox.icon:hover > .NavButtonLogo {
  height:3em;
}


/* LOGIN */
.loginPage {
  width:100%;
  height:100vh;
  background-color: #ccc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.loginHeader {
  height:2.5em;
}
.loginBox {
  background-color: #f2f2f2;
  width:20em;
  height:40em;
  border:2px solid black;
  align-items: center;
  justify-content: center;
  padding: 0.5em 2.5em
}
.loginBox.large {
  height:45em;
}
.loginTitle {
  font-size: 1.5em;
}
.loginSite {
  display:flex;
  flex-direction: column;
  justify-content: center;
  width:100%;
  border:2px solid rgba(0,0,0,0.3);
  padding:0.2em;
}
.loginString {
  font-size: 1.2em;
}
.loginInput {
  width:15em;
  font-size: 1.2em;
}
.loginFooter {

}
.loginButton {
  width:100%;
  height:2em;
  border:2px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.loginButton.blue{
  background-color: #61dafb;
}
.loginButton.gray{
  background-color: rgb(143, 148, 150);
  color:#fff;
}
.loginButtonText {

}
